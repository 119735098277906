import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AuthHeaderInterceptor } from '@app/auth/auth-interceptor';
import { I18nHeaderInterceptor } from '@app/i18n/i18n-interceptor';
import { AuthGuard } from '@app/auth/guards/auth/auth.guard';
import { AuthService } from '@app/services/auth/auth.service';
import { AppComponent } from '@app/app.component';
import { NotFoundComponent } from '@app/public/not-found/not-found.component';
import { I18nModule } from '@app/i18n/i18n.module';
import { AppRoutingModule } from '@app/app-routing.module';
import esAr from '@angular/common/locales/es-AR';
import esMx from '@angular/common/locales/es-MX';
import { CustomerGuard } from '@app/auth/guards/customer/customer.guard';
import { LogisticOperatorGuard } from '@app/auth/guards/logistic-operator/logistic-operator.guard';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { siteKey } from '@app/global-settings.data';
import { BrowserModule } from '@angular/platform-browser';
import { ShoppingCartService } from '@app/services/shopping-cart/shopping-cart.service';
import { SnackBarMessageService } from '@app/services/snack-bar-message/snack-bar-message.service';
import { StorageService } from '@app/services/storage/storage.service';
import { MainPipeModule } from '@app/pipes/main-pipe.module';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateMultiLoader } from '@app/utils/translate-multi-loader/translate-multi-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CrmService } from './services/crm/crm.service';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { Router } from '@angular/router';

registerLocaleData(esAr, 'es-AR');
registerLocaleData(esMx, 'es-MX');

const providers: any[] = [
  { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthHeaderInterceptor },
  { provide: HTTP_INTERCEPTORS, multi: true, useClass: I18nHeaderInterceptor },
  { provide: RECAPTCHA_V3_SITE_KEY, useValue: siteKey },
  { provide: 'Window', useValue: <any>window },
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false
    })
  },
  {
    provide: TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [TraceService],
    multi: true
  },
  AuthGuard,
  CustomerGuard,
  LogisticOperatorGuard,
  ShoppingCartService,
  CrmService,
  SnackBarMessageService,
  StorageService,
  AuthService
];

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    I18nModule,
    MatToolbarModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TourMatMenuModule.forRoot(),
    RecaptchaV3Module,
    BrowserModule,
    MainPipeModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.locale,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateMultiLoader([
          './assets/locale/_global',
          './assets/locale/pipes',
          './assets/locale/services',
          './assets/locale/common'
        ]),
        deps: [HttpClient]
      }
    }),
    GoogleTagManagerModule.forRoot({
      id: (<any>window).env.idGoogleTagManagerGA4
    })
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {}
