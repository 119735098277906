import {
  enableProdMode,
  TRANSLATIONS,
  TRANSLATIONS_FORMAT
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import * as Sentry from '@sentry/angular';
import { AppModule } from '@app/app.module';

if ((<any>window).env.sentryDsn != null) {
  Sentry.init({
    environment: (<any>window).env.sentryEnvironment,
    dsn: (<any>window).env.sentryDsn
  });
}

if ((<any>window).env.production) {
  enableProdMode();
}

// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string
const translations = require('raw-loader!./locale/messages.xlf');

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    { provide: TRANSLATIONS, useValue: translations },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
  ]
});
